import React from "react";
import { AppLink } from "@base";

const Home = () => {
  return (
    <div className="gap-12 bg-black items-center p-3 justify-center flex-grow text-white flex flex-col">
      <div className="w-full flex items-center justify-center">
        <svg
          version="1.2"
          baseProfile="tiny"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          className="max-w-[12rem]"
          viewBox="0 0 947.05 837.96"
          overflow="visible"
          xmlSpace="preserve"
        >
          <g>
            <path
              fill="#FFFFFF"
              d="M155.51,770.73l-2-1.33c-22.02,32.61-48.05,46.59-70.08,46.59c-29.37,0-46.72-21.3-46.72-63.23
		c0-70.55,47.39-139.77,95.44-139.77c28.7,0,44.05,27.29,55.39,71.88h2l-6.01-79.2h-2c0,6.66-2.67,9.98-10.01,9.98
		c-10.01,0-24.69-5.99-42.71-5.99C62.07,609.67,0,690.87,0,765.41c0,47.92,28.7,72.55,61.4,72.55
		C97.44,837.96,132.15,808.67,155.51,770.73z"
            />
            <path
              fill="#FFFFFF"
              d="M321.02,724.14c0-33.94-22.02-51.25-44.05-51.25c-57.4,0-96.11,71.88-96.11,113.81
		c0,33.28,21.36,50.58,44.05,51.25C282.98,837.96,321.02,765.41,321.02,724.14z M220.91,833.96c-9.34,0-15.35-5.32-15.35-19.3
		c0-21.3,16.02-70.55,34.04-102.5c13.35-22.63,26.7-36.61,40.04-36.61c9.35,0,15.35,5.99,15.35,19.97
		c0,21.3-16.02,70.55-34.04,102.5C248.27,820.65,234.93,834.63,220.91,833.96z"
            />
            <path
              fill="#FFFFFF"
              d="M427.81,786.71c0-13.98-6.67-25.29-16.68-37.27l-8.68-9.98c-13.35-15.97-20.69-25.96-20.69-39.27
		c0-15.31,11.35-24.63,24.69-24.63c13.35,0,18.69,9.32,21.36,23.96c2,8.65,6.01,13.98,14.02,13.98c6.67,0,12.01-4.66,12.01-11.98
		c0-13.98-20.69-28.62-44.72-28.62c-27.36,0-51.39,20.63-51.39,48.59c0,14.64,8.01,27.29,18.69,39.93l8.01,9.98
		c10.01,12.65,17.35,23.3,17.35,34.61c0,14.64-11.35,28.62-28.7,28.62c-16.69,0-24.03-9.32-27.37-31.95
		c-2-9.32-7.34-14.64-14.02-14.64c-6.67,0-12.01,5.32-12.01,12.65c0,18.64,20.69,36.61,51.39,37.27
		C399.11,837.96,427.81,817.99,427.81,786.71z"
            />
            <path
              fill="#FFFFFF"
              d="M449.83,834.63c5.34,0,12.01-0.67,16.68-0.67c4.67,0,11.35,0.67,16.69,0.67l73.41-109.82
		c20.69-30.62,28.7-37.27,33.37-37.27c3.34,0,5.34,2.66,5.34,6.66c0,5.32-5.34,16.64-12.68,32.61L534.6,834.63
		c5.34,0,11.35-0.67,16.02-0.67c5.34,0,11.35,0.67,16.69,0.67l75.42-109.16c20.69-30.62,28.7-37.27,33.37-37.27
		c3.34,0,5.34,2.66,5.34,6.66c0,5.32-4.67,16.64-12.68,32.61l-28.7,57.91c-5.34,10.65-10.68,24.63-10.68,33.94
		c0,9.98,6.01,17.97,18.02,17.97c16.02,0,33.37-15.97,54.06-45.92l-2-1.33c-18.69,25.96-28.7,31.95-32.7,31.95
		c-2.67,0-5.34-2-5.34-5.99c0-5.32,5.34-17.3,10.68-27.95l29.37-60.57c6.67-13.31,12.01-27.29,12.01-37.27
		c0-11.31-8.67-17.97-19.36-17.97c-18.02,0-30.7,15.31-54.73,50.58l-64.74,95.84l42.05-91.85c6.01-13.98,12.01-27.29,12.01-37.27
		c0-11.31-8.67-17.97-19.35-17.97c-18.02,0-31.37,15.31-54.73,50.58l-65.41,97.84l40.04-95.84c4.67-11.32,10.01-24.63,10.01-33.94
		c0-9.98-6.01-17.97-18.69-17.97c-16.02,0-33.37,15.97-54.06,45.93l2,1.33c19.36-25.96,28.03-31.95,32.7-31.95
		c3.34,0,5.34,2,5.34,5.99c0,5.32-4.67,16.64-9.35,27.95L449.83,834.63z"
            />
            <path
              fill="#FFFFFF"
              d="M731.48,785.38c-5.34,10.65-10.68,23.96-10.68,33.94c0,9.98,6.01,17.97,18.69,17.97
		c16.02,0,33.37-15.97,54.06-45.92l-2-1.33c-19.36,25.96-28.7,31.95-32.7,31.95c-2.67,0-5.34-2-5.34-5.99
		c0-5.32,5.34-17.3,10.68-27.95l30.03-63.23c5.34-10.65,10.68-23.96,10.68-33.94c0-9.98-6.01-17.97-18.69-17.97
		c-16.02,0-33.37,15.97-54.06,45.92l2,1.33c19.35-25.96,28.7-31.95,32.7-31.95c2.67,0,5.34,2,5.34,5.99
		c0,5.32-5.34,17.31-10.68,27.95L731.48,785.38z"
            />
            <path
              fill="#FFFFFF"
              d="M829.59,629.63c0-11.32-8.68-19.3-19.36-19.3c-10.68,0-18.69,7.99-18.69,19.3
		c0,10.65,8.01,18.64,18.69,18.64C820.91,648.27,829.59,640.95,829.59,629.63z"
            />
            <path
              fill="#FFFFFF"
              d="M912.35,672.9c-49.39,0-97.44,68.55-97.44,120.47c0,28.62,17.35,43.93,38.04,44.59
		c24.69,0,50.05-20.63,73.41-56.57l-2-1.33c-22.69,31.28-38.71,39.93-52.72,39.93c-12.68,0-21.36-9.32-21.36-33.94
		c0-43.26,32.7-110.49,64.07-110.49c9.34,0,15.35,5.99,15.35,15.31c0,12.65-10.01,21.3-10.01,31.28c0,7.32,5.34,11.98,12.01,11.98
		c9.34,0,15.35-10.65,15.35-24.63C947.05,687.54,929.7,672.9,912.35,672.9z"
            />
            <path
              fill="#FFFFFF"
              d="M439.16,94.51l-3.34-3.33c-88.1,11.31-156.17,86.52-156.17,177.71c0,91.18,68.08,166.39,156.17,177.71
		l3.34-3.33c-39.38-17.97-69.41-89.19-69.41-174.38C369.74,183.7,399.11,113.15,439.16,94.51z"
            />
            <path
              fill="#FFFFFF"
              d="M640.04,448.6v-82.53l-3.34-3.33c-10.01,40.6-42.05,72.55-82.76,82.53l3.34,3.33H640.04z"
            />
            <path
              fill="#FF634E"
              d="M549.94,87.19v4.66c44.05,8.65,78.75,43.26,87.43,87.19h4.67c8.68-43.93,43.38-78.54,87.43-87.19v-4.66
		C685.43,78.54,650.73,43.93,642.05,0h-4.67C628.7,43.93,593.99,78.54,549.94,87.19z"
            />
          </g>
        </svg>
      </div>
      <div className="flex flex-wrap gap-12">
        {/* site mapper */}
        <AppLink
          to="/voyager"
          className="h-20 flex flex-col items-center justify-end group"
        >
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 1080 1080"
            xmlSpace="preserve"
            className="w-12 my-auto block transition duration-200 transform group-hover:scale-125 group-hover:-translate-y-2"
          >
            <path
              className="fill-white"
              d="M524.8,564.9c16.2,0,29.3-13.1,29.3-29.3c0-16.2-13.1-29.3-29.3-29.3c-16.2,0-29.3,13.1-29.3,29.3
	C495.5,551.7,508.6,564.9,524.8,564.9z M524.8,525.8c5.4,0,9.8,4.4,9.8,9.8c0,5.4-4.4,9.8-9.8,9.8c-5.4,0-9.8-4.4-9.8-9.8
	C515,530.2,519.4,525.8,524.8,525.8z M410.6,608.3c-3.8-3.8-10-3.8-13.8,0l-76,76l0,0L148,857.1c-1.8,1.8-2.8,4.3-2.8,6.9
	c0,2.6,1,5,2.8,6.8l41.5,41.5c3.8,3.8,10,3.8,13.8,0l69.1-69.1l0,0l179.7-179.7c1.8-1.8,2.8-4.3,2.8-6.9c0-2.6-1-5-2.8-6.8
	l-13.9-13.9l18.2-17.8c39.8,33.1,97.6,33.1,137.4,0l285.7,286c3.8,3.8,10,3.8,13.9,0s3.8-10,0-13.9L607.4,604
	c12-14.4,19.9-31.6,23.2-50l52.6-52.2c3.8-3.8,3.9-10,0.1-13.8c0,0,0,0-0.1-0.1L653.8,459l18.8-18.3c3.8-3.8,3.9-10,0.1-13.8
	c0,0,0,0-0.1-0.1l-39.1-39.1c-3.8-3.8-10-3.9-13.8-0.1c0,0,0,0-0.1,0.1l-18.3,18.4l-28.8-29.3c-3.8-3.8-10-3.9-13.8-0.1
	c0,0,0,0-0.1,0.1l-52.5,52.6c-18.4,3.4-35.5,11.5-49.7,23.6L175,171.9c-3.8-3.8-10-3.9-13.8-0.1c0,0,0,0-0.1,0.1
	c-3.8,3.8-3.9,10-0.1,13.8c0,0,0,0,0.1,0.1l281.1,281.4c-33.1,39.8-33.1,97.6,0,137.4l-17.8,17.9L410.6,608.3z M626.6,408.5
	l25.3,25.3l-11.4,11.4l-25.3-25.3L626.6,408.5z M565.6,397.9l96.9,96.9l-30.6,31c-4.5-51.8-45.5-93-97.2-97.7L565.6,397.9z
	 M524.8,447.6c48.6,0,88,39.4,88,88c0,48.6-39.4,88-88,88c-48.6,0-88-39.4-88-88C436.9,487,476.2,447.6,524.8,447.6z M369.1,718.9
	l-27.7-27.7l20.7-20.7l27.7,27.7L369.1,718.9z M334.6,753.4L307,725.7l20.7-20.7l27.7,27.7L334.6,753.4z M300,788l-27.7-27.7
	l20.7-20.7l27.7,27.7L300,788z M265.4,822.5l-27.6-27.6l20.7-20.8l27.7,27.7L265.4,822.5z M230.9,857.1l-27.7-27.7l20.7-20.7
	l27.2,27.7L230.9,857.1z M196.3,891.7L168.7,864l20.8-20.8l27.6,27.7L196.3,891.7z M403.7,684.3l-27.7-27.7l27.7-27.7l27.7,27.7
	L403.7,684.3z"
            />
            <path
              className="fill-red"
              d="M832.6,109h-6.4c-11.9,60.3-59.6,107.9-120.1,119.8v6.4c60.5,11.9,108.2,59.4,120.1,119.8h6.4
	c11.9-60.3,59.6-107.9,120.1-119.8v-6.4C892.2,216.9,844.6,169.3,832.6,109z"
            />
          </svg>
          <span className="font-bold text-sm">Voyager</span>
          <span className="text-2xs mt-1 text-gray">Map the internet.</span>
        </AppLink>
        {/* article lister */}
        <AppLink
          to="/article-list"
          className="h-20 flex flex-col items-center justify-between group"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            className="w-8 my-auto block transition duration-200 transform group-hover:scale-125 group-hover:-translate-y-2"
          >
            <title>Newspaper</title>
            <rect
              x="96"
              y="112"
              width="96"
              height="96"
              rx="16"
              ry="16"
              fill="none"
            />
            <path
              className="fill-red"
              d="M468 112h-52v304a32 32 0 0032 32 32 32 0 0032-32V124a12 12 0 00-12-12z"
            />
            <path
              className="fill-white"
              d="M431.15 477.75A64.11 64.11 0 01384 416V44a12 12 0 00-12-12H44a12 12 0 00-12 12v380a56 56 0 0056 56h342.85a1.14 1.14 0 00.3-2.25zM96 208v-96h96v96zm224 192H96v-32h224zm0-64H96v-32h224zm0-64H96v-32h224zm0-64h-96v-32h96zm0-64h-96v-32h96z"
            />
          </svg>
          <span className="font-bold text-sm">Article List</span>
          <span className="text-2xs mt-1 text-gray">
            List all the articles.
          </span>
        </AppLink>
      </div>
    </div>
  );
};

Home.defaultProps = {};

Home.propTypes = {};

export default Home;
